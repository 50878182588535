/** @jsxImportSource theme-ui */
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Flex } from 'theme-ui';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        margin: '40px 20px 20px',
        color: (theme) => theme.colors?.gray,
        fontSize: '12px',
        textAlign: 'center',
        '@media screen and (min-width: 48em)': {
          flexDirection: 'row',
          margin: '20px',
          justifyContent: 'center',
        },
      }}
    >
      <Box>© {new Date().getFullYear()} ERGO Group AG</Box>
      <Box
        sx={{
          display: 'none',
          '@media screen and (min-width: 48em)': {
            display: 'block',
            margin: '0 12px 0 18px',
          },
        }}
      >
        |
      </Box>
      <Box
        sx={{
          margin: '0 12px',
        }}
      >
        {t('footerDescription')}
      </Box>
      <Box
        sx={{
          display: 'none',
          '@media screen and (min-width: 48em)': {
            display: 'block',
            margin: '0 8px',
          },
        }}
      >
        |
      </Box>
      <Box>
        <Link
          to="/privacy"
          sx={{
            color: (theme) => theme.colors?.gray,
            margin: '0 12px',
            textDecoration: 'none',
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('privacy.header')}
        </Link>
      </Box>
    </Flex>
  );
};
